import * as polyfills from '@spinnwerk/polyfills';

function initPolyfills() {
    polyfills.dom();
    polyfills.fetch();
    polyfills.picturefill();
    polyfills.intersectionObserver();
    polyfills.objectFit();
    polyfills.share();
}

export { initPolyfills };
