/**
 * @module application
 */

import bugsnag from '@bugsnag/js';
import Fingerprint2 from 'fingerprintjs2';

/**
 * Initializes the Bugsnag client.
 * Should not be called directly.
 * @see {@link module:application.bugsnag}
 *
 * @param {object} options
 * @param {string} options.apiKey - The API key, obtained from Bugsnag.
 * @param {string} [options.releaseStage='local'] - The current environment (e.g. local, stage, production,...).
 * @param {string|null} [options.appVersion=null] - Optional version identifier, used for grouping/filtering in the Bugsnag backend.
 * @param {boolean} [options.ignoreOldies=true] - Whether legacy browsers should be ignored.
 * @returns {Promise<void>}
 * @private
 *
 * @requires module:@spinnwerk/old-browser-check
 */
export async function initBugsnag({ apiKey, releaseStage = 'local', appVersion = null, ignoreOldies = true }) {
    let fingerprint = '',
        components;

    global.bugsnagClient = bugsnag({
        apiKey,
        appVersion,
        collectUserIp: false,
        releaseStage,
        notifyReleaseStages: ['production', 'prod', 'stage'],
        logger: null,
        beforeSend(report) {
            // there is no additional value from sending reports for legacy browsers, so they are ignored.
            // Bugsnag itself does have a feature for this in its backend, though, but that's not configurable from code
            // and also just 'discards' the reports, so its basically the same to not send them at all, but with the
            // benefit of having control over which browser get reported.
            if (ignoreOldies && global.oldie) {
                // although the browser version won't change it is saver to check whether the report should be ignored
                // here due to potential timing issues with loading and initializing Bugsnag and the old-browser-check.
                report.ignore();
            } else {
                report.user = { id: fingerprint };
            }

            // also ignore some useless errors
            if (report.errorMessage === 'ResizeObserver loop limit exceeded') {
                report.ignore();
            }
        },
    });

    // as the IP is not collected, generate a reliable fingerprint to detect how many users have the same error.
    // but getting the fingerprint is async, so include bugsnag and create the fingerprint afterwards,
    // as it is better to have a report with wrong id than at least no report at all
    components = await Fingerprint2.getPromise({
        exclude: ['fonts', 'canvas', 'webgl', 'audio'], // exclude some tests for speed
    });

    if (!components.error) {
        let values = components.map(component => component.value).join();

        fingerprint = Fingerprint2.x64hash128(values, 31);
    }
}
