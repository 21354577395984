// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
import { bugsnag } from './lib/application';
bugsnag();

// ////////////////////////////////////////////////////////////
// include needed polyfills
import { initPolyfills } from './polyfill';
initPolyfills();

// ////////////////////////////////////////////////////////////
// async css loading
import 'fg-loadcss/dist/cssrelpreload';

// ////////////////////////////////////////////////////////////
// image lazy loading
import 'lazysizes';

// enable support for native lazy loading
/* note: native lazy loading has two major downsides:
 * it loads images much earlier than lazysizes when scrolling down and has no possibility to adjust this value
 * and it loads *all* images above or near the 'fold' regardless of their current visibility state (e.g. in sliders/accordions etc).
 * See https://web.dev/native-lazy-loading#how-does-the-loading-attribute-work-with-images-that-are-in-the-viewport-but-not-immediately-visible-(for-example-behind-a-carousel)
 * If your project is fine with this, feel free to enable the following code and remove the above import:
// import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// lazySizes.cfg.nativeLoading = {
//     setLoadingAttribute: true,
//     disableListeners: true, // remove all event listeners added by lazysizes in browsers that support native lazy loading
// };
 */

// ////////////////////////////////////////////////////////////
// check if cookies are enabled
// - add the .no-cookies class to <html /> if not
let cookieEnabled = Boolean(navigator.cookieEnabled);

if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
}

if (!cookieEnabled) {
    document.documentElement.classList.add('no-cookies');
}
